.pulse-report-component {
    background-color: #F3F3F3;

    .pulse-report-content {
        padding: 15px;
        padding-top: 45px;
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;

        .MuiAccordion-root {
            margin-bottom: 16px;

            .MuiAccordionSummary-root {
                min-height: 48px;

                .MuiAccordionSummary-content {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }

            .MuiAccordionDetails-root {
                justify-content: space-between;
            }
        }
    }

    .page-banner-component {
        max-width: 1631px;
        margin-left: auto;
        margin-right: auto;
    }
}