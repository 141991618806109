.s3-preview-component {
    display: inline-block;
    vertical-align: top;
    position: relative;

    .s3-preview-image-container {
        z-index: 0;
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;
        background-color: #FFFFFF;
        cursor: pointer;

        .s3-icon-image-container {
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .s3-icon-image {
                font-size: 40px;
            }
        }

        .s3-preview-image {
            z-index: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top; // Discuss if we want
        }

        .s3-preview-image-buttons {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.5);

            .s3-preview-image-button {
                background-color: rgba(255, 255, 255, 0.85);
                font-size: 33px;
                padding: 5px 5px 0 5px;
            }
        }
    }

    .s3-name {
        cursor: pointer;
        margin-top: 5px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
    }

    .s3-preview-spinner-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
    }
}