.App-content {
    .authenticator-container {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding-top: 25px;

        amplify-authenticator {
            --container-height: 100%;
        }
    }
}

:root {
    --amplify-primary-color: #44a3f2;
    --amplify-primary-tint: #57acf3;
    --amplify-primary-shade: #3d93da;
}

// :root {
//     --amplify-primary-color: #325694;
//     --amplify-primary-tint: #47679f;
//     --amplify-primary-shade: #2d4d85;
// }