.loading-page-component {
    .logo-spinner-container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: white;
        padding-bottom: 19vh;
    }
}