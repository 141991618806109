@import "../../shared-scss/media-query-breakpoints";
@import "../../shared-scss/colors";

$side-font-size: 21px;

.page-banner-component.MuiPaper-root {
    text-align: center;
    color: $grey-text-color;
    font-size: $side-font-size;
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: 1fr 1fr 1fr;

    // override default styles
    margin-top: 2vh;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: $breakpoint-tablet-max) {
        grid-template-columns: 1fr;
    }

    .banner-center {
        &.banner-top {
            font-size: 44px;
        }

        &.banner-bottom {
            font-size: 24px;

            @media (max-width: $breakpoint-tablet-max) {
                margin-bottom: 2px;
                margin-top: 2px;
            }
        }
    }

    .banner-left {
        &.banner-top {
            position: relative;
        }

        &.banner-bottom {
            font-size: 16px;
        }
    }

    .banner-right {
        text-align: right;

        &.banner-top {
            padding-top: 0;
        }
    }

    .banner-left, .banner-right {
        padding-top: 5px;

        @media (max-width: $breakpoint-tablet-max) {
            text-align: inherit;
        }
    }
}