.selector-component {
    max-width: 100%;

    .MuiSelect-select {
        text-align-last: center;
    }

    &.MuiInput-underline:after {
        border-bottom: inherit;
    }

    .MuiSelect-root:focus {
        background-color: inherit;
    }
}