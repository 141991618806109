@import "../../../../shared-scss/colors";

.s3-delete-fab {
    background-color: $delete-red !important;
    margin-right: 7px !important;

    &:hover {
        background-color: $delete-red-hover !important;
    }
}

.s3-delete-list-item {
    .s3-delete-list-item-icon,
    .s3-delete-list-item-text {
        color: $delete-red;
    }

    &:hover {
        .s3-delete-list-item-icon,
        .s3-delete-list-item-text {
            color: $delete-red-hover;
        }
    }

    .s3-delete-list-item-icon {
        font-size: 20px;
        width: 30px;
        min-width: inherit;
    }
}