.s3-prompt {
    &.loading {
        .s3-prompt-dialog-content {
            position: relative;
            > * {
                visibility: hidden;
            }
            > .s3-folder-button-spinner-container {
                visibility: inherit;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}