@import "../../shared-scss/media-query-breakpoints";

.create-user-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .create-user-form {
        background-color: #FFF;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        padding: 40px;
        margin-top: 10%;
        width: 80%;
        max-width: 1200px;

        .create-user-pwd-user {
            display: flex;
            justify-content: space-between;

            .create-user-text-field {
                width: 49%;
            }

            @media (max-width: $breakpoint-mobile-max) {
                flex-direction: column;

                .create-user-text-field {
                    width: 100%;
                }
            }
        }
        
        .create-user-colors-clientId {
            display: flex;
            justify-content:space-between;

            .create-user-text-field {
                width: 31%;
            }

            @media (max-width: $breakpoint-mobile-max) {
                flex-direction: column;

                .create-user-text-field {
                    width: 100%;
                }
            }
        }
        
        .create-user-text-field {
            width: 100%;
            padding: 10px 0;
        }

        .user-id-intact-logo {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .create-user-text-field {
                width: 140px;
            }

            .client-logo-upload-btn {
                max-width: 450px;
                text-align: center;
                padding: 0 10px;
                border: 1px #333 dashed;

                .upload-btn-label {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                }
            }

            .client-logo-preview-btn {
                margin: 0 5px;
                border-radius: 25px;
            }

            @media (max-width: $breakpoint-mobile-max) {
                flex-direction: column-reverse;

                .create-user-text-field {
                    width: 100%;
                }
            }
        }

        .create-user-submit {
            margin-top: 40px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1.5px;
        }
    }

    .success-message {
        margin-top: 50px;
        color: #0d700d;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: capitalize;
    }

    .error-message {
        margin-top: 50px;
        color: #c10606;
        letter-spacing: 1px;
    }

    .account-info {
        background: white;
        margin-top: 50px;
        padding: 20px;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);


        position: relative;
        display: inline-block;

        .clear-accountInfo-btn {
            position: absolute;
            top: 0;
            right: 0;
        }

        p {
            margin: 5px 0;
        }

        p:first-of-type {
            margin-top: 15px;
        }

        span {
            font-family: monospace;
            padding: 4px 8px;
        }
    }
}