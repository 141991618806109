.file-share-component {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .file-share-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: calc(100% - 8vw);
        padding: 15px;
        padding-top: 20px;
        padding-left: 4vw;
        padding-right: 4vw;
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
    }
}