.s3-file-browser-component {
    flex-grow: 1;
    display: flex;

    &.loading {
        flex-grow: initial;
        display: initial;
    }

    .s3-file-browser-loader {
        margin: 15px;
    }

    .s3-file-browser-load-error {
        width: 100%;
    }
}