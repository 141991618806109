@import "../../shared-scss/colors";

$chart-title-height: 25px;

:export {
    chartTitleHeight: $chart-title-height;
}

.chart-wrapper-component {
    color: $grey-text-color;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        margin: auto;
    }

    .loaded-content {
        display: flex;
        flex-flow: column;

        .chart-title {
            font-size: 18px;
            height: $chart-title-height;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
        }

        .chart-sub-title {
            font-size: 10px;
            letter-spacing: 1px;
            margin: 0 10px;
            font-style: italic;
        }

        .chart-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            .chartjs-size-monitor {
                top: $chart-title-height;
            }
        }
    }
}