@import "../../../shared-scss/colors";

.s3-folder-component {
    text-align: left;
    position: relative;
    flex-grow: 1;
    width: 100%;

    .s3-folder-contents {
        .s3-folder-contents-empty {
            text-align: center;
            margin-bottom: 10px;
        }
    }

    .s3-folder-header {
        min-height: 25px;
        text-align: center;

        .breadcrumb-link {
            &:link, &:active, &:visited, &:hover {
                color: #000;
            }
        }

        .s3-folder-header-left {
            float: left;
            z-index: 1;
            position: relative;
        }

        .s3-folder-header-right {
            float: right;
            z-index: 2;
            position: relative;

            .s3-folder-button {
                height: 24px;
                width: 24px;
                margin-top: 0;
                margin-right: 6px;
                cursor: pointer;

                &.s3-folder-button-spinner {
                    cursor: default;
                }
            }
        }
    }

    .s3-folder-spinner-overlay,
    .s3-folder-drag-and-drop-overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .s3-folder-drag-and-drop-overlay {
        pointer-events: none;

        .s3-folder-drag-and-drop-overlay-icon {
            max-height: 100%;
            height: 100px;
            width: 100px;
        }
    }
}