.message-page-component {
    text-align: center;
    padding: 50px 15px;

    .message-page-text {
        font-size: 36px;
        padding-bottom: 20px;
    }

    .message-page-subtext {
        font-size: 20px;
    }
}