.App {
    font-family: "Open Sans", "Avenir Book";
    min-height: 100%;
    margin: 0;
    display: flex;
    flex-flow: column;
    color: #353133;

    .App-header {
        z-index: 0;
        min-height: 50px;
        width: calc(100% - 8vw);
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;

        padding-top: 2vw;
        padding-bottom: 2vw;
        padding-left: 4vw;
        padding-right: 4vw;

        border-bottom: 1px solid #353133;
    }

    .App-content {
        z-index: 1;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        flex-direction: column;
        padding-left: 4vw;
        padding-right: 4vw;
        position: relative;
    }

    .App-footer {
        z-index: 0;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        text-align: center;
        color: #777777;
        padding-top: 2vw;
        padding-bottom: 2vw;
        padding-left: 4vw;
        padding-right: 4vw;

        .app-footer-adstruc {
            opacity: 0.85;

            .app-footer-logo {
                height: 26px;
                vertical-align: middle;
                margin-right: 5px;
                margin-bottom: 2px;
            }

            .app-footer-text {
                cursor: default;
                font-size: 13px;
                position: relative;
                top: 2px;
            }
        }
    }

    .App-content, .App-footer {
        background-color: #F3F3F3;
    }

    .clearfix::after {
        content: "";
        clear: both;
        display: table;
    }

    a.nostyle:link {
        text-decoration: inherit;
        color: inherit;
        cursor: auto;
    }

    a.nostyle:visited {
        text-decoration: inherit;
        color: inherit;
        cursor: auto;
    }
}