.logo-spinner-component {
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -ms-perspective: 1200px;
    perspective: 1200px;
    text-align: center;

    /* WebKit and Opera browsers */
    @-webkit-keyframes spinner {
        from { -webkit-transform: rotateY(90deg);   }
        to   { -webkit-transform: rotateY(-270deg); }
    }

    /* all other browsers */
    @keyframes spinner {
        from {
            -moz-transform: rotateY(90deg);
            -ms-transform: rotateY(90deg);
            transform: rotateY(90deg);
        }
        to {
            -moz-transform: rotateY(-270deg);
            -ms-transform: rotateY(-270deg);
            transform: rotateY(-270deg);
        }
    }

    .logo-spinner {
        -webkit-animation-name: spinner;
        -webkit-animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;

        animation-name: spinner;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
}