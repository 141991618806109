@import "../../../../shared-scss/colors";

.s3-folder-action-menu {
    .s3-folder-action-menu-contents {
        max-width: 250px;

        .folder-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 16px 16px 8px 16px;
            font-style: italic;
            color: $grey-text-color;
            cursor: default;
        }

        .s3-folder-action-list {
            >div {
                height: 48px;
            }
        }
    }
}