.s3-base-folder {
    tr {
        th, td {
            border: 1px solid #BBBBBB;
            padding-top: 0;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }

        td {
            min-height: 36px;
            a {
                &:link, &:active, &:visited, &:hover {
                    color: #000;
                }
            }
        }

        &:first-child {
            th, td {
                border-top: 0;
            }
        }
    }
}