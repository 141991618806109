.pre-signed-link-fab {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 7px !important;
}

.pre-signed-link-list-item {
    .pre-signed-link-list-item-icon {
        font-size: 20px;
        width: 30px;
        min-width: inherit;
    }
}