.s3-create-link-prompt {
    .s3-prompt-dialog-content {
        width: inherit;

        .s3-create-link-prompt-attrs-container{
            display: flex;
            flex-direction: row;

            .s3-create-link-prompt-attr-container {
                .s3-create-link-prompt-name {
                    margin-right: 5px;
                }

                .s3-create-link-prompt-url {
                    margin-left: 5px;
                }

                &.s3-create-link-prompt-url-container {
                    flex-grow: 1;
                    margin-left: 5px;
                }
            }
        }
    }
}