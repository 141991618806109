.s3-upload {
    .s3-upload-input {
        display: none;
    }

    .s3-upload-spinner {
        color: black;
        margin-left: 5px;
        margin-bottom: -4px;
    }

    .s3-upload-icon {
        font-size: 20px;
        width: 30px;
        min-width: inherit;
    }
}