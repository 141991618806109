.header-component {
    .header-left {
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;

        .header-left-content {
            display: -webkit-box;
            max-height: 54px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 20px;
            text-overflow: ellipsis;

            .app-header-logo {
                vertical-align: middle;
            }

            .app-header-logo-separator {
                height: 36px;
                vertical-align: bottom;
            }
        }
    }

    .header-right {
        min-height: 50px;
    }
}